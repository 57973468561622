<template>
	<div 
		v-if="transaction"
		:key="'transaction_'+transaction.transactionId">
		
		<div 
			class="fixed inset-0 opacity-75 flex items-center justify-center gradientBackground"
			style="z-index: 1000;">
			
			<div id="star"></div>
		</div>
		
		<div 
			class="fixed inset-0 flex items-center justify-center dropdown-animation"
			style="z-index: 1000;">
			
			<div 
				class="relative w-100 rounded-lg  bg-gray-darkest mt-20"
				:class="profile && profile.giphyId ? 'overflow-hidden' : ''">
				
				<img 
					v-if="profile && profile.giphyId"
					:src="'https://media1.giphy.com/media/'+profile.giphyId+'/giphy.gif'"
				/>
				
				<div class="flex justify-center -mt-6 w-full">
					<img 
						v-if="transaction.user && transaction.user.picture"
						:src="fileKeyToUrl( transaction.user.picture )"
						class="rounded-full w-32 h-32 object-cover border-4 border-white"
					/>
					
					<img 
						v-else
						class="rounded-full w-32 h-32 object-cover border-4 border-white bg-white"
						src="/assets/circle-user-regular.svg"
					/>
				</div>
				
				<div class="text-center text-white px-12 py-6 text-xl">
					<p class="leading-normal">{{transactionString}}</p>
				</div>
				
				<div 
					v-if="showGiveKudo"
					class="text-center text-white px-12 pb-4">
					
					Send et hjerte eller tommel opp
					<!-- {{ loading }} -->
					
					<div 
						v-if="loading && loading == 'creatingKudo'"
						class="py-2 h-20">
						
						Sender kudos - vent litt... 
					</div>
					<div 
						v-else
						class="flex justify-center space-x-2 py-2 h-20">
						
						<div 
							@click="onCreateKudo(transaction, 'heart')"
							class="w-12 h-12 rounded-full bg-beige text-black flex items-center justify-center">
							
							<i 
								class="fas fa-heart text-center text-xl" 
							/>
						</div>
						<div 
							@click="onCreateKudo(transaction, 'thumbsUp')"
							class="w-12 h-12 rounded-full bg-beige text-black flex items-center justify-center">
							
							<i 
								class="fas fa-thumbs-up text-center text-xl" 
							/>
						</div>
					</div>
				</div>
				
			</div>
		</div>
		
		
		
		<!-- <div 
			class="fixed inset-0 flex items-center justify-center dropdown-animation"
			style="z-index: 1000;">
			
			<div 
				class="relative w-100 rounded-lg  bg-gray-darkest mt-20"
				:class="profile && profile.giphyId ? 'overflow-hidden' : ''">
				
				<img 
					v-if="profile && profile.giphyId"
					:src="'https://media1.giphy.com/media/'+profile.giphyId+'/giphy.gif'"
				/>
				
				<div class="flex justify-center -mt-6 w-full">
					<img 
						v-if="transaction.user && transaction.user.picture"
						:src="fileKeyToUrl( transaction.user.picture )"
						class="rounded-full w-32 h-32 object-cover border-4 border-white"
					/>
					
					<img 
						v-else
						class="rounded-full w-32 h-32 object-cover border-4 border-white bg-white"
						src="/assets/circle-user-regular.svg"
					/>
				</div>
				
				<div class="text-center text-white px-12 py-6 text-xl">
					<p class="leading-normal">{{transactionString}}</p>
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>
export default {
	props: {
		showGiveKudo: {
			default: true,
		},
	},
	
	computed:{
		transaction(){
			const transaction = this.$store.getters.getTransactionNotice;
			
			if (transaction){
				console.log('got transaction!');
				this.$emit('onTransaction', transaction);
			}
			
			// console.log('transactionNoticeShow', this.account?.transactionNoticeShow);
			
			if (!this.account?.transactionNoticeShow) {
				return
			}
			return transaction;
		},
		
		userFullName(){
			if ( this.transaction && this.transaction.user ) {
				return `${this.transaction.user.given_name} ${this.transaction.user.family_name}`;
			}
			
			return 'Ukjent';
		},
		
		transactionString(){
			if ( !this.transaction ) {
				return null;
			}
			
			if ( this.transaction.products && this.transaction.products.length ) {
				let totalSum = 0;
				let totalQty = 0;
				
				this.transaction.products.forEach( product => {
					totalSum += Number(product.qty) * Number(product.value);
					totalQty += Number(product.qty);
				});
				
				return `${this.userFullName} solgte nettopp ${totalQty} ${totalQty == 1 ? 'produkt' : 'produkter' } for totalt kr ${totalSum}!`;
			}
			else if ( this.transaction.tasks && this.transaction.tasks.length ) {
				let totalQty = 0;
				
				this.transaction.tasks.forEach( task => {
					totalQty += Number(task.qty);
				});
				
				return `${this.userFullName} gjorde nettopp ${totalQty} ${totalQty == 1 ? 'oppgave' : 'oppgaver' }!`;
			}
			
			return null;
		},
		
		profile(){
			if ( this.transaction.profile ) {
				return this.transaction.profile; //JSON.parse( this.transaction.profile ); 
			}
			
			return {
				giphyId: '',
			}
		},
	},
	
	data(){
		return {
			loading: null,
		}
	},
	
	methods: {
		async onCreateKudo( transaction, symbol ){
			try {
				this.loading = 'creatingKudo';
				const kudo = {
					transactionId: transaction.transactionId,
					departmentId: transaction.departmentId,
					username: transaction.username, // to username
					symbol,
				};
				
				const response = await this.$store.dispatch('createKudo', kudo);
				// this.transactions = this.transactions.filter( t => t != transaction);
				this.$notify({ type: 'success', text: 'Kudos ble sendt!' });
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message || 'Kunne ikke gi kudos'
				});
			
				console.error(e);
			} 
			finally {
				this.loading = null;
			}
		},
	},
}
</script>

<style lang="scss" >
	.dropdown-animation {
		animation: dropdown 5s ease-in-out forwards;
	}
	
	@keyframes dropdown { 
		0% { 
			transform: scale(0) translateY(-5%) rotate(180deg);
			opacity: 0;
		}
		5% { 
			transform: scale(.85) translateY(-5%) rotate(0deg);
			opacity: 1;
		} 
		95% { 
			transform: scale(1) translateY(-5%) rotate(0deg);
			opacity: 1;
		} 
		100% { 
			transform: scale(0) translateY(-5%) rotate(0deg);
			opacity: 0;
		} 
	}
	
	.gradientBackground {
		background: radial-gradient(circle,rgba(0,0,0,0),  #f0f0eb);
		animation: fadeInOut 5s ease-in forwards;
	}
	
	@keyframes fadeInOut { 
		0% { 
			opacity: 0;
		}
		10% { 
			opacity: 1;
		} 
		90% { 
			opacity: 1;
		} 
		100% { 
			opacity: 0;
		} 
	}
	
	#star {
		width: 2000px;
		height: 2000px;
		background: url(/assets/transactionStar.svg) center center no-repeat;
		background-size: 100% 100%;
		animation: 15s starRotate infinite;
		animation-timing-function: linear;
		opacity: .35;
		position: absolute;
	}
	
	@keyframes starRotate {
		0% { 
			transform: rotate(0deg) scale(1.5); 
		}
		100% { 
			transform: rotate(360deg) scale(1.5); 
		}
	}
	
</style>